import React, { useEffect, lazy, Suspense } from 'react';
import './css/style.scss';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import { initializeAnalytics } from 'firebase/analytics';
import { useDispatch } from "react-redux";
import { saveUser, saveAdmin } from "./redux/slice/authSlice";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { firebaseConfig } from './config/firebase-config';

// Lazy loading for non-essential routes
const ShopPage = lazy(() => import('./pages/ShopPage'));
const Services = lazy(() => import('./pages/Services'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogPost = lazy(() => import('./pages/BlogPost'));
const Contact = lazy(() => import('./pages/Contact'));
const TermsAndConditions = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));
const About = lazy(() => import('./pages/About'));
const ShopKit = lazy(() => import('./pages/ShopKit'));

// Initialize Firebase only once
const app = initializeApp(firebaseConfig);
initializeAnalytics(app);
const auth = getAuth(app);
const db = getDatabase(app);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(saveUser(user?.uid));
        const userRef = ref(db, `/users/${user.uid}`);
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          dispatch(saveAdmin(data?.admin || false));
        }, {
          onlyOnce: true,  // Ensure listener triggers only once to reduce unnecessary updates
        });
      } else {
        dispatch(saveUser(undefined));
        dispatch(saveAdmin(false));
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={ShopPage} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:slug" component={BlogPost} />
            <Route exact path="/terms" component={TermsAndConditions} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/about" component={About} />
            <Route exact path="/tools/german-ecommerce-toolkit" component={ShopKit} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer />
    </>
  );
}

export default React.memo(App);
